<template>
    <div>
        <particles />
        <div v-if="show">
            <div class="text_container" :style="{ width: `${container_width}px` }">
                <div class="cent topa">
                    <span>问卷类型：</span> {{ single_subject_report.type }}
                </div>
                <div class="cent">
                    <span>证型：</span> {{ single_subject_report.Stype }}
                </div>
                <div style="margin-top:10px;">
                    <van-collapse v-model="activeName1" v-for="(item, index) in single_subject_report.text" :key="index">
                        <van-collapse-item :name="index+1+''" :title="item.text_type">
                            <!-- <div v-html="item.rich_text" ></div> -->
                            <div>
                                <p style="font-size: 14px;font-family: Roboto;">{{item.pure_text
                                    }}</p>
                            </div>
                        </van-collapse-item>
                    </van-collapse>
                </div>
            </div>
            <div class="button_container" :style="{ width: `${container_width}px` }">
                <van-button @click="gotreatment()" block round type="primary">进入电激治疗</van-button>
            </div>
            <div class="button_container" :style="{ width: `${container_width}px` }">
                <van-button @click="goout()" block round type="primary">返回</van-button>
            </div>
        </div>
    </div>
</template>

<script>
import particles from "../components/particles";
import {  closeToast } from 'vant';
export default {
    name: "",
    props: {},
    components: {
        particles,
    },
    data() {
        return {
            single_subject_report: {},
            activeName1: ["1", "2",'3','4','5','6','7'],
            container_width: 0,
            background_text: null,
            disease_chart: null,
            health_chart: null,
            wx: new this.$common.WX(),
            atype: "",
            show: false,
        };
    },
    methods: {
        //  前往电刺激
        gotreatment() {
            if (!this.atype) return;
            this.$toast.loading({
                message: "推荐方案处理中...",
                forbidClick: true,
            });
            setTimeout(() => {
                this.wx.onUniapp(this.atype);
            }, 2000);
        },
        // 返回上一页
        goout() {
            this.$router.go(-1);
        },
        getSingles() {
            this.$toast.loading({
                duration: 0,
                message: "请稍等...",
                forbidClick: true,
            });
            let questionnaire_report_id =
                this.$route.query.questionnaire_report_id;
            this.$api("getSingleSubjectReport", {
                questionnaire_report_id,
            }).then((res) => {
                if (res.state !== 200) return this.$toast.fail("网咯繁忙！");
                this.single_subject_report = res.single_subject_report;
                this.atype =
                    this.single_subject_report.type +
                    "--" +
                    this.single_subject_report.Stype;
            });
        },
    },
    watch: {
        single_subject_report(val) {
            if (val != "") {
                this.show = true;
                this.$toast.clear();
            }
        },
    },
    created() {
        this.getSingles();
    },
    mounted() {
        window.onresize = () => {
            let container_width = document.body.clientWidth * 0.85;
            let brain_width = container_width * 0.2;
            this.brain_width = brain_width;
            // 子元素按父元素比例动态计算宽度，children与parent传值：[min, max]
            // parent：两个临界点父元素宽度，传值格式：[min, max]
            // children：与两个临界点对应的子元素宽度，传值格式：[min, max]
            // min_calc_rule：小于最小宽度后的子元素变化规则：y = (x + b1) * k + b2，传值格式：[b1, k, b2]，默认值
            let calc = (
                children,
                parent,
                min_calc_rule,
                width = document.body.clientWidth
            ) => {
                // 大于设定最大宽度，返回子元素最大宽度
                if (width > parent[1]) return children[1];
                // 小于设定最小宽度，按计算公式返回子元素宽度，且当width = patent[0]时，计算值与children[0]相等
                if (width < parent[0])
                    return (
                        (width + min_calc_rule[0]) * min_calc_rule[1] +
                        min_calc_rule[2]
                    );
                // 介于二者之间，按比例变化
                return (
                    ((width - parent[0]) / (parent[1] - parent[0])) *
                        (children[1] - children[0]) +
                    children[0]
                );
            };
            this.container_width = calc([920, 960], [960, 1280], [-40, 1, 0]);
            this.brain_width = calc(
                [552, 576],
                [920, 960],
                [0, 0.6, 0],
                this.container_width
            );
            this.background_text = "中医智能健康大脑 ".repeat(
                parseInt(this.container_width * 0.045)
            );
            if (this.health_chart != null)
                this.health_chart.resize({ width: this.container_width });
            if (this.disease_chart != null)
                this.disease_chart.resize({ width: this.container_width });
        };
        window.onresize();
    },
};
</script>

<style scoped >
.cent {
    text-align: center;
    font-size: 22px;
}
.topa {
    margin-top: 10px;
}
.text_container {
    height: auto;
    border-radius: 20px;
    position: relative;
    margin: 10px auto 20px;
    background: rgba(240, 240, 240, 0.8);
    overflow: hidden;
    opacity: 0.8;
}
.button_container {
    height: 60px;
    margin: 0px auto 10px;
}
</style>